/**
 * Add Class to Element
 *
 * This function exists only because IE11 does not support adding or removing
 * classes to SVG elements via `classList`.
 *
 * @see https://caniuse.com/#search=classList
 * @see https://github.com/ionic-team/stencil/pull/1082/files
 *
 * @param {Element} element
 * @param {string} cssClass
 */
export const addClass = (element, cssClass) => {
  // If there's no element, abort early
  if (!element) return;

  if (element.nodeName === 'svg') {
    let appliedClasses = element.getAttribute('class') || '';
    appliedClasses = appliedClasses.split(' ').includes(cssClass)
      ? appliedClasses
      : `${appliedClasses} ${cssClass}`;
    element.setAttribute('class', appliedClasses);
  } else {
    element.classList.add(cssClass);
  }
};

/**
 * Remove Class from Element
 *
 * This function exists only because IE11 does not support adding or removing
 * classes to SVG elements via `classList`.
 *
 * @see https://caniuse.com/#search=classList
 * @see https://github.com/ionic-team/stencil/pull/1082/files
 *
 * @param {Element} element
 * @param {string} cssClass
 */
export const removeClass = (element, cssClass) => {
  // If there's no element, abort early
  if (!element) return;

  if (element.nodeName === 'svg') {
    let appliedClasses = element.getAttribute('class') || '';
    appliedClasses = appliedClasses
      .split(' ')
      .filter((item) => item !== cssClass)
      .join(' ');
    element.setAttribute('class', appliedClasses);
  } else {
    element.classList.remove(cssClass);
  }
};
