/**
 * Turns an iterable object that is not an array into an array so you can
 * access array methods and properties.
 *
 * This is the ES5 equivalent of `[...iterable]`
 *
 * @param {any} iterable - a non-array iterable object
 */
export default function arrayFrom(iterable) {
  return Array.prototype.slice.call(iterable);
}
