import scrollLock from 'scroll-lock';
import arrayFrom from '../utilities/array-from';
import {
  showElement,
  hideElement,
} from '../utilities/transition-aware-show-hide';
// IE11 doesn't support add/remove from classList on SVG
// These functions do it the hard way for SVG elements.
import { addClass, removeClass } from '../utilities/svg-class-list';

class MegaMenu {
  /**
   * Initialize a mega menu. This JavaScript is primarily responsible for
   * binding event listeners so you can open and close the menu. It also handles
   * locking scrolling while the menu is open
   *
   * @param {Element} element - Your mega menu! An element with markup matching
   *  our mega menu pattern.
   */
  constructor(element) {
    // Register UI elements
    this.element = element;

    this.submenuUnderlay = element.querySelector(
      '.js-MegaMenu-submenuUnderlay'
    );

    this.navs = [
      {
        dropdown: element.querySelector('.js-MegaMenu-nav--main'),
        button: element.querySelector('.js-MegaMenu-hamburger'),
        icon: element.querySelector('.js-MegaMenu-hamburgerIcon'),
      },
      {
        dropdown: element.querySelector('.js-MegaMenu-nav--secondary'),
        button: element.querySelector('.js-MegaMenu-contactButton'),
        icon: element.querySelector('.js-MegaMenu-contactIcon'),
      },
    ];

    this.panelWrappers = arrayFrom(
      element.querySelectorAll('.js-MegaMenu-submenuWrapper')
    );

    // Check if the element contains the heavy page modifier class.
    // We need to change behavior slightly on pages with massive DOM trees,
    // because they can affect performance of the Mega Menu adversely.
    // You should only use this modifier on specific pages where you've
    // observed performance issues opening and closing the Mega Menu.
    this.heavyPage = element.classList.contains('js-MegaMenu--heavyPage');

    // Create placeholders for state references
    this.openWrapper = null;
    this.openNav = null;
    this.scrollingElement = null;

    // For some users we disabled animations. In those cases we can't use
    // `transitionend` listeners and need to handle events differently.
    this.prefersReducedMotion = window.matchMedia(
      '(prefers-reduced-motion: reduce)'
    ).matches;

    if (!this.prefersReducedMotion) {
      // We need to be able to add and remove event listeners.
      //
      // To make sure we can remove them correctly we create reusable
      // instances of them.
      this.panelCloseListenerInstance = this._panelCloseListener.bind(this);
      this.navCloseListenerInstance = this._navCloseListener.bind(this);
    }

    // Configure scroll-lock to avoid empty gaps when scrolling is locked and
    // the scrollbar disappears.
    this._handleScrollbarGaps();

    // Create a media query
    this.mediaQueryMatch = window.matchMedia('(min-width: 50em)');
    // Check its initial value
    this.isLargeScreen = this.mediaQueryMatch.matches;
    // Add a listener to run code when the media query status changes
    this.mediaQueryMatch.addListener((event) => {
      this.isLargeScreen = event.matches;
      this._updateScrollLock();
    });

    // Bind all of our various mouse/touch/keyboard event listeners
    this._bindPanelEvents();
    this.navs.forEach((nav) => {
      this._initNav(nav);
    });

    document.addEventListener('keydown', this._escapeListener.bind(this));
    element.addEventListener('click', this._outsideClickListener.bind(this));

    this._syncSearchInputs();
  }

  /**
   * Add click and touch events for our hamburger menu button
   * @param {Object} nav
   * @param {HTMLElement} nav.dropdown - our nav dropdown
   * @param {HTMLElement} nav.button - a button (or anchor with role="button") that
   *  toggles our nav
   */
  _initNav(nav) {
    if (!nav.dropdown || !nav.button || !nav.button) return;

    // In some cases we progressively enhance links into buttons when JS loads.
    // (If JS doesn't load they maintain their link behavior)
    if (nav.button.tagName !== 'BUTTON') {
      nav.button.setAttribute('role', 'button');
    }

    nav.button.setAttribute('aria-expanded', 'false');

    nav.button.addEventListener('click', (e) => {
      if (this.openNav === nav) {
        this._closeTopLevelNav(nav);
      } else {
        this._openTopLevelNav(nav);
      }

      // Prevent this bubbling up and triggering our document click listener
      e.preventDefault();
      e.stopPropagation();
    });
  }

  /**
   * Add open and close controls for our submenus
   */
  _bindPanelEvents() {
    // Iterate over each submenu, adding click events
    this.panelWrappers.forEach((wrapper) => {
      // Grab the trigger and the panel
      const controls = this._getPanelControls(wrapper);

      // Confirm we have our necessary elements before proceeding
      if (controls.trigger && controls.panel) {
        // We're progressively enhancing links with JS. If JS never loads then the
        // links work normally. If JS does load we turn these links into buttons
        // that toggle submenus. We add ARIA attributes to communicate this to
        // screen readers.
        controls.trigger.setAttribute('role', 'button');
        controls.trigger.setAttribute('aria-expanded', 'false');
        controls.trigger.setAttribute(
          'aria-controls',
          controls.trigger.dataset.controls
        );
        // We also need to remove one attribute to finish the transition to button
        controls.trigger.removeAttribute('aria-current');

        controls.trigger.addEventListener('click', (event) => {
          if (this.openNav) {
            // Remove this event listener if it's lingering
            this.openNav.dropdown.removeEventListener(
              'transitionend',
              this.panelCloseListenerInstance
            );
          }

          // Get current state of this submenu so we can toggle it
          const wasShowing =
            controls.trigger.getAttribute('aria-expanded') === 'true';

          const oldWrapper = this.openWrapper;

          // Remove any lingering transition classes on this panel
          this._removeTransitionClasses(controls.panel);

          if (wasShowing) {
            // This panel was showing before and is being toggled closed now.
            this._hideOpenPanel();

            this._hideSubmenuUnderlay();
          } else {
            // If a different panel was previously open we need to handle the
            // transition between the two panels (assuming we haven't disabled)
            // animations.
            if (oldWrapper) {
              if (this.prefersReducedMotion) {
                // Show the panel immediately if they prefer reduced motion
                this._showPanel(controls);
              } else if (this.isLargeScreen) {
                // If we're on a large screen and they prefer motion we'll perform
                // a swap animation

                // Get the old panel for manipulation
                const oldPanel = this._getPanelControls(oldWrapper).panel;

                // Remove any lingering transition classes
                this._removeTransitionClasses(oldPanel);

                // Depending on whether the new panel's trigger is to the left or
                // right of the old panel's trigger we add different classes.
                // These classes impact large screen transitions.
                const oldIndex = this.panelWrappers.indexOf(oldWrapper);
                const newIndex = this.panelWrappers.indexOf(wrapper);

                if (newIndex > oldIndex) {
                  controls.panel.classList.add('is-right', 'is-entering');
                  oldPanel.classList.add('is-left', 'is-exiting');
                } else {
                  controls.panel.classList.add('is-left', 'is-entering');
                  oldPanel.classList.add('is-right', 'is-exiting');
                }

                this._showPanel(controls);
              } else {
                // If we're on a small screen and another panel was open we want to
                // wait for it to close before opening a new one.
                this.panelCloseCallback = () => {
                  this._showPanel(controls);
                };

                if (this.openNav) {
                  // Remove this event listener if it's lingering
                  this.openNav.dropdown.removeEventListener(
                    'transitionend',
                    this.panelCloseListenerInstance
                  );
                }
              }
            } else {
              // If a different wrapper wasn't open previously then we just do a
              // normal panel open.
              this._showPanel(controls);

              // We make the header fixed position when the menu is open. When we close
              // the menu the header goes back to its regular position which can look
              // odd. Resetting the page scroll to the top helps avoid this.
              window.scrollTo(0, 0);
            }

            // Hide the previously open panel, and show our new panel.
            // This is done down here so that any necessary transition classes
            // are applied before we trigger transitions.
            this._hideOpenPanel();

            this.openWrapper = wrapper;
          }

          // On large screens opening a panel can update our scroll locking.
          // If this panel was previously showing, or no panel was showing before
          // then we need to lock scrolling
          if (this.isLargeScreen && (wasShowing || !oldWrapper)) {
            this._updateScrollLock();
          }

          // Prevent default link behavior. This is done for progressive
          // enhancement. If JS doesn't load, the native link behavior still works
          event.preventDefault();
          // Prevent this event from bubbling up to our document click listener
          event.stopPropagation();
        });
      }
    });
  }

  // When someone presses the escape key we'll close open menus
  _escapeListener(event) {
    // IE expects `Esc`, not `Escape`
    if (event.key === 'Escape' || event.key === 'Esc') {
      this._closeAll();
    }
  }

  // When someone clicks outside of the mega menu we want to close open menus
  _outsideClickListener(event) {
    // We want to ignore clicks within the mega menu. Depending on whether
    // you're on a small or large screen the clickable area varies based on the
    // different visual design.
    const clickTrapper = this.isLargeScreen
      ? this.openWrapper.querySelector('.js-MegaMenu-scrollable')
      : this.openNav && this.openNav.dropdown;

    if (!clickTrapper) return;

    const ignoreClick = clickTrapper.contains(event.target);

    if (!ignoreClick) {
      this._closeAll();
    }
  }

  _closeAll() {
    if (this.openNav) {
      this._closeTopLevelNav(this.openNav);
    } else if (this.openWrapper) {
      this._hideOpenPanel();
      this._hideSubmenuUnderlay();
    }

    this._updateScrollLock();
  }

  _openTopLevelNav(nav) {
    if (this.openNav) {
      this._closeTopLevelNav(this.openNav);
    }

    addClass(nav.icon, 'is-on');
    nav.button.setAttribute('aria-expanded', false);

    this.openNav = nav;

    // We make the header fixed position when the menu is open. When we close
    // the menu the header goes back to its regular position which can look
    // odd. Resetting the page scroll to the top helps avoid this.
    window.scrollTo(0, 0);

    // Remove `display: none;` from our nav
    nav.dropdown.classList.add('is-transitioning');

    // Force a browser re-paint so the browser will realize the
    // element is no longer `display: none` and allow transitions.
    /* eslint-disable-next-line no-unused-vars */
    const reflow = nav.dropdown.offsetHeight;

    // Trigger CSS animations on nav children
    nav.dropdown.classList.add('is-open');

    // Remove this event listener if it's lingering
    nav.dropdown.removeEventListener(
      'transitionend',
      this.navCloseListenerInstance
    );
  }

  _closeTopLevelNav(nav) {
    removeClass(nav.icon, 'is-on');
    nav.button.setAttribute('aria-expanded', false);

    // If a panel's open close it before closing the its parent nav
    if (this.openWrapper) {
      this._hideOpenPanel();
      this._hideSubmenuUnderlay();

      if (this.prefersReducedMotion) {
        // Close the top level nav immediately if they prefer reduced motion
        this._hideTopLevelNav(nav);
      } else {
        // Wait for the panel to close before closing the menu
        this.panelCloseCallback = () => {
          this._hideTopLevelNav(nav);
        };

        nav.dropdown.addEventListener(
          'transitionend',
          this.panelCloseListenerInstance
        );
      }
    } else {
      // If not, close the top level nav immediately
      this._hideTopLevelNav(nav);
    }

    this.openNav = null;
  }

  _navCloseListener(event) {
    // Make sure the transition happened on this element's underlay
    if (event.target === this.submenuUnderlay) {
      const nav = event.target.closest('.js-MegaMenu-nav');

      // Hide the nav completely (`display: none;`)
      nav.classList.remove('is-transitioning');

      nav.removeEventListener('transitionend', this.navCloseListenerInstance);
    }
  }

  /**
   * If a panel is open when we close the its parent nav or open another panel,
   * we wait for the panel to close before performing the other action.
   *
   * We store the action to perform in `this.panelCloseCallback`
   *
   * @param {Event} event - a transitionend event
   */
  _panelCloseListener(event) {
    // Make sure the transition end event is on submenu
    if (event.target.classList.contains('js-MegaMenu-submenu')) {
      if (this.openNav) {
        // Reset the nav scrolling after the panel closes
        this.openNav.dropdown.scrollTo(0, 0);
      }

      if (this.panelCloseCallback) {
        this.panelCloseCallback();
      }

      if (this.openNav) {
        this.openNav.dropdown.removeEventListener(
          'transitionend',
          this.panelCloseListenerInstance
        );
      }
    }
  }

  _hideTopLevelNav({ dropdown }) {
    // Trigger CSS hiding animations on nav children
    dropdown.classList.remove('is-open');
    if (this.prefersReducedMotion) {
      dropdown.classList.remove('is-transitioning');
    } else {
      dropdown.addEventListener('transitionend', this.navCloseListenerInstance);
    }
  }

  /**
   * Hide a panel's content
   * @param {HTMLElement} root0
   * @param {HTMLElement} root0.trigger
   * @param {HTMLElement} root0.panel
   */
  _hidePanel({ trigger, panel }) {
    trigger.setAttribute('aria-expanded', false);

    // IE11 doesn't support `classList` on SVGs so we'll use this function
    removeClass(
      trigger.querySelector('.js-MegaMenu-submenuExpandIcon'),
      'is-on'
    );

    hideElement(panel);
  }

  /**
   * Specifically close the currently open panel.
   */
  _hideOpenPanel() {
    if (this.openWrapper) {
      this._hidePanel(this._getPanelControls(this.openWrapper));

      this.openWrapper = null;
    }
  }

  /**
   * Show a panel's content
   * @param {HTMLElement} root0
   * @param {HTMLElement} root0.trigger
   * @param {HTMLElement} root0.panel
   */
  _showPanel({ trigger, panel }) {
    trigger.setAttribute('aria-expanded', true);

    // IE11 doesn't support `classList` on SVGs so we'll use this function
    addClass(trigger.querySelector('.js-MegaMenu-submenuExpandIcon'), 'is-on');

    showElement(panel);
    showElement(this.submenuUnderlay);

    // If the panel contains a search input we should focus it so they can
    // immediately start typing.
    const panelSearchInput = panel.querySelector('.js-mega-menu-search-input');

    if (panelSearchInput) {
      panelSearchInput.focus();
    }
  }

  /**
   * Take a wrapper element and returns the button trigger and panel from within
   * that wrapper.
   * @param {HTMLElement} wrapper
   */
  _getPanelControls(wrapper) {
    return {
      trigger: wrapper.querySelector('.js-MegaMenu-submenuToggle'),
      panel: wrapper.querySelector('.js-MegaMenu-submenu'),
    };
  }

  /**
   * Hide the submenu underlay
   */
  _hideSubmenuUnderlay() {
    hideElement(this.submenuUnderlay);

    if (!this.isLargeScreen) {
      // If this is a small screen the underlay won't transition, so won't
      // fire a `transitionend` event for `toggleVisibility` to hook into.
      // We need to manually add `hidden`;
      this.submenuUnderlay.setAttribute('hidden', '');
    }
  }

  /**
   * Different transition classes are applied depending in different
   * circumstances. We want to be sure to remove old transition classes
   * before hiding or showing this panel.
   * @param {HTMLElement} panel
   */
  _removeTransitionClasses(panel) {
    panel.classList.remove('is-left', 'is-right', 'is-entering', 'is-exiting');
  }

  /**
   * This locks or unlocks page scrolling based upon the current state of the
   * mega menu. There's a fair chunk of logic here, since the page functions
   * differently depending on page size.
   *
   * On small screens our navs hidden behind a toggles. Once a nav is open,
   * scrolling should be locked.
   *
   * On large screens most of the menu is always visible, with submenus being
   * hidden until they're open. When a submenu's open scrolling should be locked.
   */
  _updateScrollLock() {
    // If we call disable or enable multiple times in a row they can get queued
    // up and lead to errors. The docs recommend clearing the queue if there's
    // a chance this has happened.
    //
    // @see https://github.com/FL3NKEY/scroll-lock#queue
    scrollLock.clearQueueScrollLocks();

    if (this.isLargeScreen) {
      // On large screens we pad the submenu content when scrolling is locked
      // Scroll lock will handle this for us if we add a fill gap selector.
      //
      // However, it allows duplicate fill gap selectors. This can lead to
      // poor performance since Scroll Lock loops over those selectors to
      // update the DOM. To avoid this we remove the selector before adding it,
      // to ensure there are no duplicates.
      //
      // @see https://github.com/FL3NKEY/scroll-lock/issues/13
      scrollLock.removeFillGapSelector('.js-MegaMenu-scrollable');
      scrollLock.addFillGapSelector('.js-MegaMenu-scrollable');

      // Handle large screens
      if (this.openWrapper) {
        // If a submenu is open, disable scrolling
        // However, allow scrolling within the submenu
        this.scrollingElement = this.openWrapper.querySelector(
          '.js-MegaMenu-scrollable'
        );
        this._disableScroll();
      } else {
        this._enableScroll();
      }
    } else {
      // On small screens we don't pad the submenu content when scrolling is locked
      scrollLock.removeFillGapSelector('.js-MegaMenu-scrollable');

      // Handle small screens
      if (this.openNav) {
        // Disable scrolling if a top level nav is open
        // However, allow scrolling within that nav
        this.scrollingElement = this.openNav.dropdown.querySelector(
          '.js-MegaMenu-primaryLinks'
        );
        this._disableScroll();
      } else {
        // Otherwise enable scrolling
        this._enableScroll();
      }
    }
  }

  /**
   * The MegaMenu is tightly coupled with the PageContent pattern.
   * We need to add and remove classes from PageContent when toggling the menu,
   * but we can't select it in the constructor because we're loading this
   * script before the PageContent in the markup, which means the content
   * doesn't exist in the DOM yet when the constructor runs.
   */
  _getPageContent() {
    return document.querySelector('.js-PageContent');
  }

  /**
   * Disable page scrolling while allowing scrolling
   * within `this.scrollingElement`
   */
  _disableScroll() {
    // Adding this class will make the header `position: fixed;`
    this.element.classList.add('is-open');

    // Adding this class will add margin to compensate for the fixed header.
    this._getPageContent().classList.add('is-underMegaMenu');

    // If this is a heavy page, the scroll locking mechanism causes a dramatic
    // performance decrease, especially in Safari 14, so we're opting out.
    if (this.heavyPage) return;

    scrollLock.disablePageScroll(this.scrollingElement);
  }

  /**
   * Enable page scrolling and clear any data
   * attributes previously added to `this.scrollingElement`
   */
  _enableScroll() {
    // Removing this class will make the header `position: relative;`
    this.element.classList.remove('is-open');

    // Adding this class will add margin to compensate for the fixed header.
    this._getPageContent().classList.remove('is-underMegaMenu');

    // If this is a heavy page, the scroll locking mechanism causes a dramatic
    // performance decrease, especially in Safari 14, so we're opting out.
    if (this.heavyPage) return;

    scrollLock.enablePageScroll(this.scrollingElement);
  }

  /**
   * Some users may have their OS configured to always show scrollbars.
   * If this is the case, their scrollbar will disappear when we lock
   * scrolling. This can cause content to shift around in a jarring fashion
   * as the page fills up the space that the scrollbar occupied.
   *
   * The ScrollLock library provides some utilities to help prevent this.
   * To do so, it adds padding to elements equal to the space the scrollbar
   * used to take up.
   *
   * We need to do some custom configuration for this to work correctly.
   *
   * @see https://github.com/FL3NKEY/scroll-lock#filling-the-gap
   */
  _handleScrollbarGaps() {
    /**
     * By default `ScrollLock` adds padding to the `body` but this can cause
     * problems when the body contains full width elements, as they'll no longer
     * span the full window.
     *
     * We disable this default behavior.
     */
    scrollLock.removeFillGapSelector('body');

    /**
     * Since the default behavior is disabled it's up to us to fill the
     * scrollbar gap where necessary.
     *
     * Most pages are composed of the mega menu, the page content, and the
     * footer. We'll instruct ScrollLock to fill the gap on these elements.
     */
    scrollLock.addFillGapSelector('.js-MegaMenu');
    scrollLock.addFillGapSelector('.js-PageContent-inner');
    scrollLock.addFillGapSelector('.js-FooterContent');
  }

  /**
   * The placement of the search input is different on small and large screens.
   * In order to maintain a reasonable tab order on all screen sizes we ended up
   * including 2 different search forms, one for small screens and one for large
   * screens.
   *
   * We would like to keep these two forms in sync in case a user changes their
   * screen size.
   *
   * This function watches each search input and keeps them synced up.
   */
  _syncSearchInputs() {
    const inputs = arrayFrom(
      document.querySelectorAll('.js-mega-menu-search-input')
    );

    inputs.forEach((input) => {
      // Watching change will not sync the inputs on every keystroke, but that's
      // fine since only one input will be visible and interactive at a time.
      input.addEventListener('change', () => {
        const newValue = input.value;

        inputs.forEach((loopedInput) => {
          // We'll ignore the input that just change
          if (input !== loopedInput) {
            // But update the other input.
            loopedInput.value = newValue;
          }
        });
      });
    });
  }
}

export default MegaMenu;
