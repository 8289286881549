/**
 * Get First Item
 *
 * This is a sanity check function for code where you can't be sure whether
 * you'll be passed a single item or a jQuery collection.
 *
 * Accepts either a DOM Element from `document.querySelector`,
 * a collection of DOM Elements from methods like `document.querySelectorAll`,
 * or a jQuery selection object, and returns a single DOM Element.
 *
 * @param {jQuery|NodeList|HTMLCollection|Element} selection - a collection or single element
 * @returns {Element} a DOM element
 */
export const getFirstItem = (selection) => {
  // Abort early if we somehow have an empty collection
  if (selection.length === 0) return null;

  // If this is a collection, return the first item.
  if (selection.length > 0) return selection[0];

  // If this is not a collection, return the original item.
  return selection;
};
